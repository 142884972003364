import { Container, Grid } from '@material-ui/core'
import React from 'react'
import GridRow from './_grid-row'

export default function GridCols() {
    return (
        <React.Fragment>
            <div className="grid-cols">
                <Container maxWidth="xl">
                    <GridRow>
                        <Grid container alignItems="stretch" spacing={3}>
                            <Grid item xs>
                                <div className="grid-col-pink"></div>
                            </Grid>
                            <Grid item xs>
                                <div className="grid-col-pink"></div>
                            </Grid>
                            <Grid item xs>
                                <div className="grid-col-pink"></div>
                            </Grid>
                            <Grid item xs>
                                <div className="grid-col-pink"></div>
                            </Grid>
                            <Grid item xs>
                                <div className="grid-col-pink"></div>
                            </Grid>
                            <Grid item xs>
                                <div className="grid-col-pink"></div>
                            </Grid>
                            <Grid item xs>
                                <div className="grid-col-pink"></div>
                            </Grid>
                            <Grid item xs>
                                <div className="grid-col-pink"></div>
                            </Grid>
                            <Grid item xs>
                                <div className="grid-col-pink"></div>
                            </Grid>
                            <Grid item xs>
                                <div className="grid-col-pink"></div>
                            </Grid>
                            <Grid item xs>
                                <div className="grid-col-pink"></div>
                            </Grid>
                            <Grid item xs>
                                <div className="grid-col-pink"></div>
                            </Grid>
                        </Grid>
                    </GridRow>
                </Container>
            </div>
        </React.Fragment>
    )
}
