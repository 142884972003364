/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme, CssBaseline } from "@material-ui/core";
import '../assets/scss/global.scss'
import GridCols from "./_grid_cols";
import { orange } from "@material-ui/core/colors";

const Layout = ({ children, pageName, backLink, noMargin, liveStream }) => {
  const theme = createMuiTheme({
    palette: {
      primary: orange
    },
    breakpoints: {
      values: {
        xs: 0,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1700
      },
    },
    overrides: {
      MuiContainer: {
        maxWidthXl: {
          maxWidth: '1368px !important',
          paddingLeft: '24px',
          paddingRight: '24px'
        }
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GridCols />
      <Header pagename={pageName} backLink={backLink} noMargin={noMargin} liveStream={liveStream}/>
      <main>{children}</main>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
