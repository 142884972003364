import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import PrimarySearchAppBar from "./menu-component"
const Header = ({ siteTitle,pagename, backLink, noMargin, liveStream}) => (
 

    <PrimarySearchAppBar PageName={pagename} backLink={backLink} noMargin = {noMargin} liveStream={liveStream}/>
 

)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
