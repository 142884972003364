import React, { useContext } from 'react'
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import menuStyle from '../assets/scss/menu.module.scss';
import { Container, Grid, Icon, MenuItem } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import GridRow from './_grid-row';
import UserContext from '../providers/user-provider'
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../images/BM.png';
import menuicon from "../images/MenuIcon.png";
import { Link } from "gatsby";
import { Button } from '@material-ui/core';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import clsx from 'clsx';
import List from '@material-ui/core/List';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
const useStyles = makeStyles({
  list: {
    width: 250,
    backgroundColor: '#231f20',
    color: '#fada4d',
  },
  fullList: {
    width: 'auto',
  },
});

export default function PrimarySearchAppBar({ PageName, backLink, noMargin, liveStream }) {
  const classes = useStyles();
  const { user, handleLogout } = useContext(UserContext);
  const [state, setState] = React.useState({

    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    console.log("bahi", event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className={menuStyle.sideMenuItems}>
        <div className={menuStyle.menuItems}>
          <Link className={menuStyle.sideMenuLink} to="/">Home</Link>
          <Link className={menuStyle.sideMenuLink} to="/team">Meet the Team</Link>
          <Button aria-controls="mobile-menu" aria-haspopup="true" onClick={handleClick}
            endIcon={<ArrowDropDownIcon classes={{root: `${menuStyle.dropdownIcon}`}}/>}
          >
            <span style={{color: "#fada4d"}} className={menuStyle.sideMenuLink}>Train</span>
          </Button>
          <Menu
            id="mobile-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            classes={{paper: `${menuStyle.menuDropdown}`}}
          >
            <MenuItem classes={{root: `${menuStyle.dropdownItem}`}}><Link className={menuStyle.menuLink} to="/all-classes" onClick={handleClose}>Classes</Link></MenuItem>
            <MenuItem classes={{root: `${menuStyle.dropdownItem}`}}><Link className={menuStyle.menuLink} to="/events" onClick={handleClose}>Events</Link></MenuItem>
            <MenuItem classes={{root: `${menuStyle.dropdownItem}`}}><Link className={menuStyle.menuLink} to="/specialized-training" onClick={handleClose}>Specialized Training</Link></MenuItem>
            <MenuItem classes={{root: `${menuStyle.dropdownItem}`}}><Link className={menuStyle.menuLink} to="/education" onClick={handleClose}>Education</Link></MenuItem>
          </Menu>
          <Link className={menuStyle.sideMenuLink} to="/merchandise">Merchandise</Link>
          {/* {user ?
            <>
              <Link className={menuStyle.menuLink} to="/history">History</Link>
              <Link className={menuStyle.menuLink} to="#">Notifications</Link>
              <Link className={menuStyle.menuLink} to="/profile">Profile</Link>
              <Link className={menuStyle.SignIn} to="#" onClick={handleLogout}>Sign out</Link>
            </>
            :
            <Link className={menuStyle.SignIn} to="/signin">Sign in</Link>
          } */}
        </div>
      </List>

    </div>
  );

  return (
    
    <div className={liveStream  ? `${menuStyle.displayNone} ${menuStyle.menuContainer}` : noMargin ? `${menuStyle.menuContainer}` : `${menuStyle.menuContainer} ${menuStyle.menuMargin}`}>
      <Container maxWidth="xl" >
        <GridRow>
          <Grid container spacing={3}>

            <AppBar position="static">
              <Toolbar className={menuStyle.dFlex}>
                {
                  PageName == "Home" ? <img src={logo} className={menuStyle.logo} />
                    : <div className={menuStyle.backBtn}><Link to={backLink? backLink : "/"} className={menuStyle.arrowHeight}><ArrowBackIcon /></Link><span className={menuStyle.menuHeader}>{PageName}</span></div>
                }
                <Typography variant="inherit" className={menuStyle.linksWrapper}>
                  <Link className={menuStyle.menuLink} to="/">Home</Link>
                  <Link className={menuStyle.menuLink} to="/team">Meet the Team</Link>
                  <Button aria-controls="desktop-menu" aria-haspopup="true" onClick={handleClick}
                    endIcon={<ArrowDropDownIcon classes={{root: `${menuStyle.dropdownIcon}`}}/>}
                  >
                    <span style={{color: "#fada4d"}}>Train</span>
                  </Button>
                  <Menu
                    id="desktop-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    classes={{paper: `${menuStyle.menuDropdown}`}}
                  >
                    <MenuItem classes={{root: `${menuStyle.dropdownItem}`}}><Link className={menuStyle.menuLink} to="/all-classes" onClick={handleClose}>Classes</Link></MenuItem>
                    <MenuItem classes={{root: `${menuStyle.dropdownItem}`}}><Link className={menuStyle.menuLink} to="/events" onClick={handleClose}>Events</Link></MenuItem>
                    <MenuItem classes={{root: `${menuStyle.dropdownItem}`}}><Link className={menuStyle.menuLink} to="/specialized-training" onClick={handleClose}>Specialized Training</Link></MenuItem>
                    <MenuItem classes={{root: `${menuStyle.dropdownItem}`}}><Link className={menuStyle.menuLink} to="/education" onClick={handleClose}>Education</Link></MenuItem>
                  </Menu>
                  <Link className={menuStyle.menuLink} to="/merchandise">Merchandise</Link>
                  {/* {user ?
                    <>
                      <Link className={menuStyle.menuLink} to="/history">History</Link>
                      <Link className={menuStyle.menuLink} to="#">Notifications</Link>
                      <Link className={menuStyle.menuLink} to="/profile">Profile</Link>
                      <Link className={menuStyle.SignIn} to="#" onClick={handleLogout}>Sign out</Link>
                    </>
                    :
                    <Link className={menuStyle.SignIn} to="/signin">Sign in</Link>
                  } */}


                </Typography>
                <IconButton onClick={toggleDrawer('right', true)} edge="end" className={menuStyle.menuButton} color="inherit" aria-label="menu">
                  <img src={menuicon} className={menuStyle.menuIcon} />
                </IconButton>
                <SwipeableDrawer
                  anchor={'right'}
                  open={state['right']}
                  onClose={toggleDrawer('right', false)}
                  onOpen={toggleDrawer('right', true)}
                >
                  {list('right')}
                </SwipeableDrawer>
              </Toolbar>
            </AppBar>

          </Grid>
        </GridRow>
      </Container>
    </div>
  );
}
