import React from 'react';

export default function GridRow({children}) {

    return (
        <React.Fragment>
            <div className='grid-row'>
                {children}
            </div>
        </React.Fragment>
    )
}
