import { Container, Grid, Hidden, Icon } from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import { MailOutlined, PhoneOutlined, RoomOutlined } from '@material-ui/icons';
import React from 'react';
import footerStyles from '../assets/scss/footer.module.scss';
import GridRow from './_grid-row';
import { Link } from "gatsby";
import appStoreImg from '../images/appstore-logo.png';
import playStoreImg from '../images/playstore-logo.png';


export default function Footer() {
    return (
        <React.Fragment>
            <footer className={footerStyles.footer}>
                <Container maxWidth="xl">
                    <GridRow>
                        <Grid container spacing={3} direction="row-reverse">
                            <Hidden mdDown>
                                <Grid item xs={12} lg={2}></Grid>
                            </Hidden>
                            <Hidden lgUp>
                                <Grid item xs={12} md={1}></Grid>
                            </Hidden>
                            <Grid item xs={12} md={3}>
                                <h3 className="h3">
                                    Coming soon!
                                </h3>
                                <div className={footerStyles.appDownload}>
                                    <img src={appStoreImg} alt="Apple app store download app" />
                                    <img src={playStoreImg} alt="Google play store download app" />
                                </div>
                            </Grid>
                            <Hidden mdDown>
                                <Grid item xs={12} lg={1}></Grid>
                            </Hidden>
                            <Hidden lgUp>
                                <Grid item xs={12} md={3}></Grid>
                            </Hidden>
                            <Grid item xs={12} md={5} lg={3}>
                                <h3 className="h3">
                                    Find us
                                </h3>
                                <ul className={footerStyles.ulFind}>
                                    <li>
                                        <span className={footerStyles.icon}><RoomOutlined style={{color: yellow}}/></span>
                                        <a href="https://g.page/makanibeachclub?share" target="_blank"><span className="text-02">North Beach, Red Sea Governorate 84513, Makani Beach Club, El Gouna, Egypt.</span></a>
                                    </li>
                                    <li>
                                        <span className={footerStyles.icon}><MailOutlined style={{color: yellow}}/></span>
                                        <a href="mailto:info@beachmonkeyz.com"><span className="text-02">info@beachmonkeyz.com</span></a>
                                    </li>
                                    <li>
                                        <span className={footerStyles.icon}><PhoneOutlined style={{color: yellow}}/></span>
                                        <a href="tel:+20 122 737 5335"><span className="text-02">+20 122 737 5335</span></a>
                                    </li>
                                </ul>

                                <h3 className="h3">
                                    Follow us
                                </h3>
                                <ul className={footerStyles.ulFollow}>
                                    <li>
                                        <a href="https://www.facebook.com/beachmonkeyz/" target="_blank">
                                            <Icon className="fab fa-facebook" style={{color: yellow}}/>
                                        </a>

                                        <a href="https://www.instagram.com/beachmonkeyz/" target="_blank">
                                            <Icon className="fab fa-instagram" style={{color: yellow}}/>
                                        </a>
                                    </li>

                                </ul>
                            </Grid>
                            <Hidden mdDown>
                                <Grid item xs={12} lg={3}></Grid>
                            </Hidden>
                            <Grid item xs={12}>
                            <div className={footerStyles.flexCopyright}>
                                <p className={`text-02 ${footerStyles.copyright}`}>&copy; 2021 Beach Monkeyz&trade;. All rights reserved.</p>
                                <p className={`text-02 ${footerStyles.copyright} ${footerStyles.poweredBy}`}> Powered by <a href="https://effvision.com" >Effvision</a></p>
                                </div>
                            </Grid>
                        </Grid>
                    </GridRow>
                </Container>
            </footer>
        </React.Fragment>
    )
}
